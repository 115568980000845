<div class="settings-container">
  <div class="setting-header">{{ "profile.language" | translate }}</div>

  <div class="languages">
    <ng-container *ngFor="let language of languages">
      <p-radioButton
        [name]="language.label"
        [value]="language"
        [label]="language.label"
        [(ngModel)]="selectedLanguage"
        (ngModelChange)="selected($event)"
      ></p-radioButton>
    </ng-container>
  </div>
</div>

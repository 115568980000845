import { ITenantConfiguration } from './interfaces/tenant-configuration.interface';

export class TenantConfiguration {
  allowedGroupsCount: number;
  communicationApp: string;
  defaultThemeColor: string;
  disabledFeatures: string[];
  forcePrivacyPolicyCheck: boolean;
  profileEditPermissions: ProfileEditPermissions;
  tenant: string;
  approveStoryEnabled: boolean;
  documentsEnabled: boolean;

  constructor(data: ITenantConfiguration) {
    this.allowedGroupsCount = data.attributes.allowed_groups_count;
    this.communicationApp = data.attributes.communication_app;
    this.defaultThemeColor = data.attributes.default_theme_color;
    this.forcePrivacyPolicyCheck = data.attributes.force_privacy_policy_check;
    this.disabledFeatures = data.attributes.disabled_features;
    this.profileEditPermissions = {
      description: data.attributes.profile_edit_permissions.description,
      phoneNumber: data.attributes.profile_edit_permissions.phone_number,
      profileImage: data.attributes.profile_edit_permissions.profile_image,
    };
    this.tenant = data.attributes.tenant;
    this.approveStoryEnabled = data.attributes.feature_story_approval;
    this.documentsEnabled = data.attributes.feature_documents;
  }
}

export interface ProfileEditPermissions {
  profileImage: boolean;
  phoneNumber: boolean;
  description: boolean;
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrl: './select-language.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLanguageComponent {
  selectedLanguage = '';

  languages = [
    {
      label: 'English',
      value: 'en',
      termsAndConditionsUrl: 'https://jenz.app/privacy-policy/?preview=true',
    },
    {
      label: 'Deutsch',
      value: 'de',
      termsAndConditionsUrl: 'https://jenz.app/privacy-policy-de/?preview=true',
    },
    {
      label: 'Hrvatski',
      value: 'hr',
      termsAndConditionsUrl: 'https://jenz.app/privacy-policy-hr/?preview=true',
    },
    {
      label: 'Italiano',
      value: 'it',
      termsAndConditionsUrl: 'https://jenz.app/privacy-policy-it/?preview=true',
    },
    {
      label: 'Português',
      value: 'pt',
      termsAndConditionsUrl: 'https://jenz.app/privacy-policy-pt/?preview=true',
    },
    {
      label: 'Slovenščina',
      value: 'sl',
      termsAndConditionsUrl: 'https://jenz.app/privacy-policy-sl/?preview=true',
    },
  ];

  @Output() languageSelected = new EventEmitter<string>();

  selected(language) {
    this.languageSelected.emit(language);
  }
}

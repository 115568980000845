<ng-container
  *ngIf="termsAndConditionsAcceptedOnInit; else termsAndConditionsDialog"
>
  <ng-container *ngIf="pageReady$ | async; else showJenzLoading">
    <app-layout></app-layout>
  </ng-container>

  <ng-template #showJenzLoading>
    <div class="main-spinner-container">
      <p-progressSpinner
        strokeWidth="3"
        styleClass="whole-page-spinner"
        animationDuration="5s"
      ></p-progressSpinner>
      <img src="assets/images/jenz-logo.png" class="spinner-jenz-logo" />
    </div>
  </ng-template>

  <p-toast></p-toast>
</ng-container>

<ng-template #termsAndConditionsDialog>
  <p-dialog
    [modal]="true"
    [closable]="false"
    [visible]="true"
    [draggable]="false"
    styleClass="terms-and-conditions-dialog"
  >
    <ng-container
      *ngIf="!selectedLanguage || !termsAndConditionsAcceptedOnInit"
    >
      <app-select-language
        (languageSelected)="selectLanguage($event)"
      ></app-select-language>
      <p-footer>
        <div class="privacy-policy-area">
          <p-checkbox
            [(ngModel)]="termsAndConditionsAccepted"
            binary="true"
          ></p-checkbox>
          <h3 class="confirmation-text">
            I have read and accept
            <a [href]="termsAndConditionsUrl" target="_blank">privacy policy</a>
          </h3>
        </div>
        <div class="next-button-area">
          <button
            pButton
            [disabled]="!termsAndConditionsAccepted || !currentLanguage"
            [label]="'general.next' | translate"
            class="next-button"
            (click)="languageSelectedAndTermsAndConditionsAccepted()"
          ></button>
        </div>
      </p-footer>
    </ng-container>
  </p-dialog>
</ng-template>
